import React from "react";
import { connect } from "react-redux";
function NotFound404() {
    return (
        <>
            <div className="max-w-7xl m-auto text-center md:pt-24 md:pb-10 text-7xl h-screen">
                404 - Page Not Found
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(NotFound404);
