import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import {
    getRemoveFile,
    getUploadFile,
} from "../../store/Actions";

function FilesTable({
    doGetUploadFile,
    doGetRemoveFile,
    files,
}) {
    const [ filesDisplay, setFilesDisplay ] = useState(new Array(5).fill({}));
    
    const fileInputRef = useRef();
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file.size > 100000) {
            toast.error("File is too large, try shrinking file before uploading.  There is a 100 kb limit per file.", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        };
        // Alert user that files must be less than 1/2 megabyte
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function() {
            doGetUploadFile({file: reader.result, name: file.name});
        }
    };

    useEffect(() => {
        let rowCount = 0;
        const rowMax = 5;
        let temporaryFilesDisplay = [];
        files.map((file) => {
            let workingFile = { ...file};
            rowCount++;
            const dateTime = new Date(workingFile.created.seconds * 1000);
            const displayDate = `${dateTime.getFullYear()}-${dateTime.getMonth() + 1}-${dateTime.getDate()} ${dateTime.getHours()}:${dateTime.getMinutes()}`;
            workingFile["date"] = displayDate;
            temporaryFilesDisplay.push(workingFile);
            return file;
        });
        for(let i = rowCount; i < rowMax; i++) {
            temporaryFilesDisplay.push({
                name: "Upload File",
                date: "",
                status: "empty"
            });
        }
        setFilesDisplay(temporaryFilesDisplay);
    }, [files]);
    return (
        <div className="border border-gray-300 rounded-lg overflow-hidden">
            <table className="table-auto w-full">
                <tbody className="text-black-text">
                    { filesDisplay.map((file) => {
                        return (
                            <tr className={`border border-gray-300 ${file.status === "empty" ? "bg-gray-100/40" : "bg-gray-100"}`}> 
                                <td className="p-2 text-sm">
                                    { file.name }
                                </td>
                                <td className="py-2 text-sm w-28">
                                    { file.date }
                                </td>
                                <td className="py-2 text-sm">
                                    { file.status !== "empty" &&
                                        <div className="bg-gray-100 uppercase">
                                            { file.status }
                                        </div>
                                    }
                                </td>
                                <td className="py-2 text-sm w-24 px-2">
                                    { file.status === "empty" ? 
                                        <div className="rounded-lg bg-green-button px-2 py-1 text-center text-white hover:cursor-pointer" onClick={handleButtonClick}>
                                            Upload
                                        </div>
                                     : file.status === "uploading" || file.status === "deleting" ? 
                                        <></>
                                     : 
                                        <div className="rounded-lg bg-primary-red px-2 py-1 text-center text-white hover:cursor-pointer" onClick={()=>doGetRemoveFile(file.fileId)}>
                                            Delete
                                        </div>
                                    }
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        accept="text/*"
                                        style={{ display: 'none' }} // Hide the file input
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doGetRemoveFile: payload => dispatch(getRemoveFile(payload)),
    doGetUploadFile: (payload) => dispatch(getUploadFile(payload)),
});

const mapStateToProps = (state) => ({
    files: state.fileSlice.files,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesTable);
