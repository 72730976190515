import React from "react";

function EmailStatus({status}) {
    return (
        <>
            {status === "unread" && 
                <div className="rounded-lg p-1 bg-gray-text/70 text-white font-medium w-24">
                    Unread
                </div>
            }
            {status === "read" && 
                <div className="rounded-lg p-1 bg-gray-text/20 text-black font-medium w-24">
                    Read
                </div>
            }
            {status === "loading" && 
                <div className="rounded-lg p-1 bg-gray-text/20 text-black font-medium w-24">
                    loading
                </div>
            }
            {status === "loading repsonse" && 
                <div className="rounded-lg p-1 bg-gray-text/20 text-black font-medium w-24">
                    loading
                </div>
            }
            {status === "ai response generated" && 
                <div className="rounded-lg p-1 bg-primary-red text-white font-medium w-24">
                    Ready
                </div>
            }
            {status === "draft sent" && 
                <div className="rounded-lg p-1 bg-green-button text-white font-medium w-24">
                    Draft Saved
                </div>
            }
        </>
    );
}

export default EmailStatus;
