import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import BouncyRedButton from "../ui/BouncyRedButton";
import BouncyWhiteButton from "../ui/BouncyWhiteButton";
import {
    appInit,
    logout,
} from "../../store/Actions";

function Header({
    doAppInit,
    doLogout,
    client,
    user,
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const onClickLogout = () => {
        doAppInit();
        doLogout();
        navigate("/");
    }

    return (
        <>        
            <header className="bg-white w-full">
                <div className="max-w-7xl m-auto text-left text-xl flex flex-row justify-between bg-white p-2.5 px-10 pt-3">
                    <div className="justify-between flex flex-row text-black-text text-3xl font-bold mt-2">
                        <img className="h-10 pr-2 -mt-1" src="/images/logo_tilt.png" alt="Email with a face and flying hat"/>
                        <Link to="/">Respond Pilot</Link>
                    </div>
                    <nav className="hidden md:flex flex-row grid grid-cols-2 gap-2">
                        { ((Object.keys(client).length !== 0 && user.status === "paid") && location.pathname !== "/emails") &&
                            <div className="pt-2 flex flex-row">
                                <BouncyRedButton to="/emails">
                                    My Account
                                </BouncyRedButton>
                            </div>
                        }
                        { (Object.keys(client).length !== 0 && user.status === "paid") ?
                            <div className="pt-1 flex flex-row">
                                <div onClick={onClickLogout} className="mt-1">
                                    <BouncyWhiteButton to="">
                                        Logout
                                    </BouncyWhiteButton>
                                </div>
                            </div>
                        :
                            <BouncyRedButton to="/login">
                                Login/ Signup
                            </BouncyRedButton>
                        }
                    </nav>
                     <div className="float-right text-right md:hidden w-1/4">
                        <button type="button"
                            className=" inline-flex items-center justify-center rounded-md p-2.5 text-gray-700  bg-teal-button"
                            onClick={() => setIsNavOpen((prev) => !prev)}
                            >
                            <span className="sr-only">Open main menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div className={`h-32 max-w-7xl md:hidden ${isNavOpen ? "" : "hidden"}`} role="dialog" aria-modal="true">
                        <div className="fixed inset-0 z-50"></div>
                        <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-8 py-3 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                            <div className="flex items-center justify-between">
                                <div className="justify-between text-teal-text italic text-3xl font-extrabold">
                                    <Link to="/">Respond Pilot</Link>
                                </div>
                                <button type="button"
                                    className=" rounded-md p-2.5 text-gray-700"
                                    onClick={() => setIsNavOpen(false)}>
                                    <span className="sr-only">Close menu</span>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="mt-6 flow-root">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-6 flex flex-col">
                                        <div onClick={onClickLogout} className="mt-1">
                                            Logout
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doAppInit: payload => dispatch(appInit()),
    doLogout: payload => dispatch(logout()),
});

const mapStateToProps = (state) => ({
    client: state.userSlice.client,
    user: state.userSlice.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
