import { put, call, takeLatest, takeLeading, fork, all, select } from 'redux-saga/effects';
import {
    AUTH_LOGIN,
    login,
    logout,
    setClient,
    setUser,
    setWebpraisalValue,
    clearUserData,
    verifyTokenOrInvalidate,
} from '../../store/Actions';

import {
    fetchFirebaseLogin,
    fetchValidateUser,
    fetchLogin,
} from '../../api/Api';

const selectGmailClient = (state) => state.userSlice.client;

/**
 * Handle User Login and take Google Code
 **/ 
function* handleLogin({payload}) {
    try {
        const loginResponse = yield call(fetchLogin, payload);

        let client = loginResponse.data.client;
        yield put(setClient(client));

        let user = loginResponse.data.user;
        yield put(setUser(user));

        yield put(setWebpraisalValue({index: "signInSuccess", value: true}));
        yield put(setWebpraisalValue({index: "isLoading",value: false}));
    } catch (error) {
        yield put(setWebpraisalValue({index: "isError",value: true}));
        yield put(setWebpraisalValue({index: "errorMessage",value: error.message}));
    }
}

//
// Verify User token and clear client if not in good standing
//
function* handleVerifyTokenOrInvalidate() {
    try {
        //Select client
        const client = yield select(selectGmailClient);
        //If clien is empty throw error
        if (Object.keys(client).length === 0) {
            throw new Error("No Client, redirect");
        }
        //If client, validate token, review db user and if in good standing
        const newClient = yield call(fetchValidateUser, {client: client});
        if (newClient.data.status === "invalid") {
            //If not valid redirect
            throw new Error("Invalid user, redirect");
        }
        //If valid set new client
        yield put(setClient(newClient.data.client));
        yield put(setUser(newClient.data.user));
    } catch (error) {
        //If not valid redirect
        yield put(setClient({}));
    }
}

function* handleLogout(data) {
    yield put(clearUserData());
}

function* getAuthLogin(data) {
    const login = yield call(fetchFirebaseLogin, data.payload);
    yield put(setUser(login.data));
}


function* watchAuthSaga() {
    yield takeLeading(login.type, handleLogin);
    yield takeLeading(verifyTokenOrInvalidate.type, handleVerifyTokenOrInvalidate);
    yield takeLatest(logout.type, handleLogout);
    yield takeLatest(AUTH_LOGIN, getAuthLogin);
}

export default function* authSagas() {
    yield all([
        fork(watchAuthSaga),
    ]);
}