import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import AdminLayout from "../../components/admin/Layout";
import {
    firebaseAdminRequest,
} from "../../store/Actions";

const AdminRequest = ({
    doFirebaseAdminRequest,
    userToken,
    request,
    hasPreviousPage,
    hasMore,
}) => {
    const { id } = useParams();

    const memoizedDoFirebaseAdminRequest = useCallback(() => {
        doFirebaseAdminRequest({
            id: id,
            userToken: userToken,
        });
    }, [doFirebaseAdminRequest, id, userToken]);

    useEffect(() => {
        memoizedDoFirebaseAdminRequest()
    }, [memoizedDoFirebaseAdminRequest]);

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">
                Requests
            </div>
            <div className="flex flex-col max-w-4xl p-5">
                <div className="p-10">
                    id: {request.id}<br/>
                    Type: {request.requestType}<br/>
                    Search Term: {request.searchTerm}<br/>
                    Survey Resonse: {request.surveyResponse}<br/>
                    Response: {request.response}<br/>
                </div>
            </div>
        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseAdminRequest: (data) => dispatch(firebaseAdminRequest(data)),
});

const mapStateToProps = (state) => ({
    request: state.firebase.request,
    userToken: state.userSlice.customToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRequest);