import React from "react";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from "./Header";
import Footer from "./Footer";

function Layout({ children }) {

    return (
        <div className="bg-white-gray min-h-screen">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Header/>
            <div className="bg-white md:h-2">
                <img className="transform rotate-180 " src="/images/paper-tear-top.svg" alt="Description"/>
            </div>
            <div className="">
                { children }
            </div>
            <Footer/>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
