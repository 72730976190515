import { createSlice } from '@reduxjs/toolkit';

export const ContactSlice = createSlice({
    name: "file",
    initialState: {
        contacts: [],
    },
    reducers: {
        collectContact: () => {},
    },
});

export const { 
    collectContact,
} = ContactSlice.actions;

export const selectContacts = (state) => state.contactSlice.files;

export default ContactSlice.reducer;
