export const GET_EMAILS = "GET_EMAILS";
export const GET_EMAILS_REDIRECT_URL = "GET_EMAILS_RECTIRECT_URL";
export const GET_EMAIL_CREATE_AND_SEND = "GET_EMAIL_CREATE_AND_SEND";
export const GET_EMAIL_CREATE_DRAFT = "GET_EMAIL_CREATE_DRAFT";

export const GET_EMAIL_CLIENT_AND_USER_PROFILE = "GET_EMAIL_CLIENT_AND_USER_PROFILE";

export const GET_GENERATE_AI_RESPONSE = "GET_GENERATE_AI_RESPONSE";

export const GET_AI_LIST_FILES = "GET_AI_LIST_FILES";
export const GET_AI_UPLOAD_FILE = "GET_AI_UPLOAD_FILE";
export const GET_AI_REMOVE_FILE = "GET_AI_REMOVE_FILE";

export const INIT = "INIT";

export const SUBSCRIBE_WITH_PAYMENT = "SUBSCRIBE_WITH_PAYMENT";

export const subscribeWithPayment = (data) => ({
    type: SUBSCRIBE_WITH_PAYMENT,
    payload: data,
});

export const appInit = () => ({
    type: INIT,
});

export const getAiRemoveFile = (data) => ({
    type: GET_AI_REMOVE_FILE,
    payload: data,
});

export const getAiUploadFile = (data) => ({
    type: GET_AI_UPLOAD_FILE,
    payload: data,
});

export const getAiListFiles = (data) => ({
    type: GET_AI_LIST_FILES,
    payload: data,
});

export const getEmailCreateDraft = (data) => ({
    type: GET_EMAIL_CREATE_DRAFT,
    payload: data,
});

export const getEmailsRedirectUrl = (data) => ({
    type: GET_EMAILS_REDIRECT_URL,
    payload: data,
});

export const getEmailClientAndUserProfile = (data) => ({
    type: GET_EMAIL_CLIENT_AND_USER_PROFILE,
    payload: data,
});


export const SET_WEBPRAISAL_VALUE = "SET_WEBPRAISAL_VALUE";
export const APPEND_WEBPRAISAL_VALUE = "APPEND_WEBPRAISAL_VALUE";
export const SET_WEBPRAISAL_STATUS = "SET_WEBPRAISAL_STATUS";
export const SET_IS_LOADING_TRUE = "SET_IS_LOADING_TRUE";
export const WEBPRAISAL_SET_RESULT = "WEBPRAISAL_SET_RESULT";

export const setIsLoadingTrue = () => ({
    type: SET_IS_LOADING_TRUE
});

export const appendWebpraisalValue = (data) => ({
    type: APPEND_WEBPRAISAL_VALUE,
    payload: data,
});

export const setWebpraisalValue = (data) => ({
    type: SET_WEBPRAISAL_VALUE,
    payload: data,
});

