import React, { useRef, useState} from "react";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import {
    collectContact,
} from "../store/Actions";

function Contact({
    doCollectContact,
}) {
    const [ formSubmitted, setFormSubmitted ] = useState(false);
    const recaptchaRef = useRef();
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        contactMethod: "",
        message: "",
    });

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (recaptchaValue) {
            doCollectContact(formData);
            setFormSubmitted(true);
        }
    }
    return (
        <>
            <div className="max-w-7xl m-auto text-center md:pt-24 md:pb-10">
                <div className="font-semibold text-7xl">
                    <span>Reach out to </span>
                    <span className="text-green-button">Respond Pilot.</span>
                </div>
                <div className="font-semibold text-4xl pt-24">
                    <div>Connect with us on social media</div>
                    <div>[Icons]</div>
                </div>
            </div>
            <div className="bg-white h-10 mt-16">
                <img src="/images/paper-tear-top.svg" alt="Description"/>
            </div>
            <div className="bg-white px-4">
                { formSubmitted ?
                    <div className="text-5xl text-center py-40">
                        Thank you, we will contact you shortly.
                    </div>
                :
                    <form onSubmit={handleSubmit}>
                        <div className="max-w-4xl m-auto md:py-24 grid grid-cols-2 gap-4">
                            <div className="col-span-2 font-semibold text-4xl text-center pb-2">Complete the form below and we’ll respond ASAP.</div>
                            <div>
                                <div className="font-semibold">Your Name</div>
                                <input className="border-2 border-black text-xl px-5 py-2 rounded-lg w-full"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange} />
                            </div>
                            <div>
                                <div className="font-semibold">Email Address</div>
                                <input className="border-2 border-black text-xl px-5 py-2 rounded-lg w-full"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange} />
                            </div>
                            <div>
                                <div className="font-semibold">Phone Number</div>
                                <input className="border-2 border-black text-xl px-5 py-2 rounded-lg w-full"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}/>
                            </div>
                            <div>
                                <div className="font-semibold">Preferred contact method</div>
                                <select className="border-2 border-black text-xl px-5 py-2 rounded-lg w-full" name="contactMethod"
                                    value={formData.contactMethod}
                                    onChange={handleChange}>
                                    <option value="">Select an option</option>
                                    <option value="email">Email</option>
                                    <option value="phone">Phone</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <div className="font-semibold">Message</div>
                                <textarea className="border-2 border-black text-xl px-5 py-2 rounded-lg w-full h-52"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}/>
                            </div>
                            <div className="pt-1 pb-5 m-auto col-span-2">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6Lf0k1YpAAAAABC1BEAVzGxRIZ-S6HUeqN6fFLry"
                                    onChange={handleRecaptchaChange}
                                />
                            </div>
                            <div className="col-span-2 text-center">
                                <button type="submit" className="text-white text-lg font-semibold bg-primary-red rounded-lg py-4 px-7">
                                    Send Form
                                </button>
                            </div>
                        </div>
                    </form>
                }
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doCollectContact: payload => dispatch(collectContact(payload)),
});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
