import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    getRedirectUrl,
} from "../../store/Actions";
function GoogleLogin({ 
    doGetRedirectUrl,
    redirectUrl,
}) {
    const memoizedDoGetEmailsRedirectUrl = useCallback(() => {
        doGetRedirectUrl()
    }, [doGetRedirectUrl]);
    useEffect(() => {
        memoizedDoGetEmailsRedirectUrl();
    }, [memoizedDoGetEmailsRedirectUrl]);
    return (
        <>
            <Link to={redirectUrl}>
                <div className="border-2 border-black rounded-lg flex flex-row w-fit overflow-hidden m-auto">
                    <div className="bg-white w-fit p-2">
                        <img className="h-10" src="/images/google.svg" alt="Google Sign in Button"/>
                    </div>
                    <div className="bg-black w-full text-white p-2 text-3xl font-semibold">
                        Sign in with Google
                    </div>
                </div>
            </Link>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doGetRedirectUrl: payload => dispatch(getRedirectUrl()),
});

const mapStateToProps = (state) => ({
    redirectUrl: state.emailSlice.redirectUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogin);
