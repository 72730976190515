import { createSlice } from '@reduxjs/toolkit';

export const EmailSlice = createSlice({
    name: "email",
    initialState: {
        emails: [],
        emailOpenIndex: null,
        redirectUrl: null,
    },
    reducers: {
        setRedirectUrl: (state, action) => { state.redirectUrl = action.payload },
        getRedirectUrl: () => {},
        setOpenEmailIndex: (state, action) => {
            state.emailOpenIndex = action.payload;
        },
        setEmail: (state, action) => { 
            const { index, value } = action.payload;
            state.emails[index] = value;
        },
        setEmailStatusAndResponseById: (state, action) => {
            const { id, status, response } = action.payload;
            state.emails.forEach(email => {
                if (email.emailId === id) {
                    email.status = status;
                    email.response = response;
                }
            });
        },
        setEmailStatusById: (state, action) => {
            const { id, status } = action.payload;
            state.emails.forEach(email => {
                if (email.emailId === id) {
                    email.status = status;
                }
            });
        },
        clearEmails: (state, action) => { 
            state.emails = [];
            state.emailOpenIndex = null;
            state.redirectUrl = null;
        },
        setEmails: (state, action) => { state.emails = action.payload },
        setEmailToRead: () => {},
        getEmail: () => {},
        getEmails: () => {},
        getEmailDraft: () => {},
        getGenerateAiResponse: () => {},
        getRefresh: () => {},
    },
});

export const { 
    setEmail,
    setEmails,
    setEmailToRead,
    getEmail,
    getEmails,
    setEmailStatusAndResponseById,
    setEmailStatusById,
    setOpenEmailIndex,
    setRedirectUrl,
    getRedirectUrl,
    getEmailDraft,
    getGenerateAiResponse,
    getRefresh,
    clearEmails,
} = EmailSlice.actions;
export const selectEmails = (state) => state.emailSlice.emails;

export default EmailSlice.reducer;
