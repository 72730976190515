import React from "react";
import { connect } from "react-redux";
function TermsOfService() {
    return (
        <>
            <div className="max-w-7xl m-auto text-center md:pt-24 md:pb-10 text-7xl">
                Terms Of Service
            </div>
            <div className="max-w-4xl m-auto pb-10">
                Last Updated: 1/21/2024<br/><br/>

Welcome to Respond Pilot. These Terms of Service ("Terms") govern your use of the Respond Pilot application and services (collectively, the "Service"), provided by Respond Pilot. By accessing or using our Service, you agree to be bound by these Terms.
<br/><br/>
1. Service Description<br/><br/>
Respond Pilot is an AI-powered application designed to assist users in generating email responses. Our Service connects to your email account to facilitate the creation of drafts and responses using AI technology.
<br/><br/>
2. Data Use and Storage<br/><br/>
We do not store your emails on our servers.
Only AI-generated responses are stored to enhance and improve the Service.
We take reasonable measures to protect the data we store.<br/><br/>
3. Subscription and Credits<br/><br/>
Access to Respond Pilot is provided on a subscription basis.<br/><br/>
You are not purchasing the software but are subscribing to use our services.<br/><br/>
The Service usage is limited by credits, which are consumed as you use the application.<br/><br/>
4. No Liability<br/><br/>
Respond Pilot is not liable for any damages or losses resulting from your use of the Service.
You use the Service at your own risk.<br/><br/>
5. User Responsibilities<br/><br/>
You agree to use Respond Pilot only for lawful purposes and in compliance with all applicable laws and regulations.<br/><br/>
You are responsible for maintaining the confidentiality of your account and password.<br/><br/>
6. Opt-In and Communication<br/><br/>
By using Respond Pilot, you opt-in to our services.<br/><br/>
You may receive communication related to the Service, such as updates or newsletters.<br/><br/>
7. Changes to Terms<br/><br/>
We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting.
Continued use of the Service after changes constitutes your acceptance of the new Terms.<br/><br/>
8. Contact Information<br/><br/>
If you have any questions or concerns regarding these Terms, please contact us at [Your Contact Information].
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);
