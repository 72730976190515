import React from "react";

function ContentBox({ children, className }) {
    return (
        <div className={`border border-gray-border/20 rounded-lg overflow-scroll-y overflow-hidden ${className}`}>
            { children }
        </div>
    );
}

export default ContentBox;
