import { all, fork, takeLeading, put, call, select } from "redux-saga/effects";
import { toast } from 'react-toastify';

import {
    setFiles,
    addFilePending,
    removeFilePending,
    removeFileUpdateStatus,
    getListFiles,
    getUploadFile,
    getRemoveFile,
} from "../../store/Actions";

import {
    fetchAiListFiles,
    fetchAiUploadFile,
    fetchAiRemoveFile,
} from "../../api/Api";

const selectGmailClient = (state) => state.userSlice.client;

function* handleRemoveFile({payload}) {
    try {
        toast.warning('Deleting File', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        const gmailClient = yield select(selectGmailClient);
        yield put(removeFileUpdateStatus(payload));
        yield call(fetchAiRemoveFile, {
            fileId: payload,
            client: gmailClient,
        });
        yield put({ type: getListFiles.type });
        toast.success('File Deleted', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* handleUploadFile({payload}) {
    try {
        toast.info('Uploading file', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        yield put(addFilePending(payload));
        const gmailClient = yield select(selectGmailClient);
        yield call(fetchAiUploadFile, {
            file: payload.file,
            name: payload.name,
            client: gmailClient,
        });
        yield put({ type: getListFiles.type });
        toast.success('File Uploaded!', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        yield put(removeFilePending(payload));
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* handleListFiles(payload) {
    try {
        const gmailClient = yield select(selectGmailClient);
        const files = yield call(fetchAiListFiles, { client: gmailClient});
        yield put(setFiles(files.data.files));
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* watchDataSaga() {
    yield takeLeading(getListFiles.type, handleListFiles);
    yield takeLeading(getUploadFile.type, handleUploadFile);
    yield takeLeading(getRemoveFile.type, handleRemoveFile);

}

export default function* dataSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}
