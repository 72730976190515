import {
    FIREBASE_SET_VALUE,
} from '../../store/Actions';

const initialState = {
    settings: {
        openAiTurbo: "",
        averageTimeToComplete: 0,
    },
    isLoading: false,
    emailSubmitted: false,
    analytics: [],
    requests: {
        edges: [],
        hasMore: false,
        hasPreviousPage: false,
    },
    request: {},
    emails: {
        edges: [],
        hasMore: false,
        hasPreviousPage:false,
    },
};

const FirebaseReducer = (state = initialState, action) => {
    switch(action.type) {
    case FIREBASE_SET_VALUE:
        let returnValue = { ...state };
        returnValue[action.payload.index] = action.payload.value;
        return returnValue;
    default:
        return {
            ...state,
        }
    }
}

export default FirebaseReducer;