import React, { useState } from 'react';
import { connect } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import {
    subscribeWithPayment
} from "../../store/Actions";
import BouncyRedSubmit from "../ui/BouncyRedSubmit";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const CheckoutForm = ({
    subscriptionOption,
    doSubscribeWithPayment,
}) => {
    const [zip, setZip] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [formError, setFormError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                address: {
                    postal_code: zip,
                },
            },
        });

        if (error) {
            console.log('[error]', error);
            setFormError(error.message);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            setFormError(null);
            // Send paymentMethod.id to your server for processing
            doSubscribeWithPayment({
                subscription: subscriptionOption,
                paymentMethod: paymentMethod,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col ">
            <div className="flex flex-col p-4">
              <label>
                  <div className="font-bold">Card Number</div>
                  <CardNumberElement className="bg-white p-4 ring-2 rounded-lg ring-black mb-4 focus:drop-shadow-[1px_1px_1px_rgba(17,17,17,17.25)] transition"/>
              </label>
                <div className="grid grid-cols-2 gap-5">
                    <label>
                        <div className="font-bold">Expiration Date</div>
                        <CardExpiryElement className="bg-white p-4 ring-2 rounded-lg ring-black mb-4 focus:drop-shadow-[1px_1px_1px_rgba(17,17,17,17.25)] transition"/>
                    </label>
                    <label>
                        <div className="font-bold">CVC</div>
                        <CardCvcElement className="bg-white p-4 ring-2 rounded-lg ring-black mb-4 focus:drop-shadow-[1px_1px_1px_rgba(17,17,17,17.25)] transition"/>
                    </label>
                </div>
              <label>
                  <div className="font-bold">ZIP Code</div>
                  <input
                    type="text"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    placeholder="ZIP Code"
                    className="bg-white p-4 ring-2 rounded-lg ring-black focus:drop-shadow-[1px_1px_1px_rgba(17,17,17,17.25)] transition"
                    required
                  />
              </label>
                { formError &&
                    <div className="bg-primary-red/50 w-full h-10 rounded-lg pt-1 text-center m-4">
                        {formError}
                    </div>
                }
            </div>
            <BouncyRedSubmit disabled={!stripe}>
                Register
            </BouncyRedSubmit>
        </form>
    );
};

const mapDispatchToProps = (dispatch) => ({
    doSubscribeWithPayment: payload => dispatch(subscribeWithPayment(payload)),
});

const mapStateToProps = (state) => ({
    user: state.userSlice.user,
});

const CheckoutFormConnected = connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);

const StripeCheckout = ({subscriptionOption}) => (
    <Elements stripe={stripePromise}>
        <CheckoutFormConnected subscriptionOption={subscriptionOption}/>
    </Elements>
);

export default StripeCheckout;