import React, { useState } from "react";

function BouncyRedSubmit({
    children,
}) {
    const [activeClass, setActiveClass] = useState("");

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }
    return (
    <>
        <div className="text-center bouncy-button-container relative w-fit m-auto hover:cursor-pointer h-12 z-20"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleClick}
            onMouseUp={handleUnclick}
        >
            <div className="absolute w-full bg-gray-600 h-12 -z-10 rounded-lg">
            </div>
            <button type="submit" className={`bg-primary-red rounded-lg text-white
                text-2xl py-2 px-4 bouncy-button z-10 w-fit font-semibold ${activeClass}`}
                >
                { children }
            </button>
        </div>
    </>
    )
}

export default BouncyRedSubmit;
