import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import RegistrationForm from "../components/registration/RegistrationForm";

import {

} from "./../store/Actions";

function Registration({
    user,
    client,
    step,
    transition,
}) {
    const navigate = useNavigate();

    useEffect(() => {
        if (user.status === "paid") {
            navigate("/emails");
        }
    }, [navigate, user]);

    useEffect(() => {
        if (Object.keys(client).length === 0) {
            navigate("/");
        }
    }, [client, navigate]);
    return (
        <>
            <div className="min-h-[500px]">
                { step === 1 &&
                    <div className={`registration ${transition && "fade-out"}`}>
                        <RegistrationForm/>
                    </div>
                }

                { step === 2 &&
                    <div className={`text-3xl text-center pt-10 pb-10registration ${transition && "fade-out"}`}>Creating Account...</div>
                }

                { step === 3 &&
                    <div className={`text-3xl text-center pt-10 pb-10registration ${transition && "fade-out"}`}>Creating AI Assistant For you...</div>
                }

                { step === 4 &&
                    <div className={`text-3xl text-center pt-10 pb-10registration ${transition && "fade-out"}`}>Getting Account Ready...</div>
                }
            </div>
            <div className="bg-white h-10 mt-16">
                <img src="/images/paper-tear-top.svg" alt="Description"/>
            </div>

        </>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    user: state.userSlice.user,
    client: state.userSlice.client,
    step: state.uiSlice.registrationStep,
    transition: state.uiSlice.transition,
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
