import { REHYDRATE } from 'redux-persist';
import {
    SET_WEBPRAISAL_VALUE,
    APPEND_WEBPRAISAL_VALUE,
    SET_IS_LOADING_TRUE,
    INIT,
} from '../../store/Actions';

const initialState = {
    isError: false,
    errorMessage: "",
    signInSuccess: false,
    isLoading: false,
    files:[],
};

const WebpraisalReducer = (state = initialState, action) => {
    switch(action.type) {
        case INIT:
            return { ...initialState, redirectUrl: state.redirectUrl };
        case REHYDRATE:
            return { ...state, ...(action.payload?.webpraisal || {}) };
        case SET_IS_LOADING_TRUE:
            return {
                ...state,
                isLoading: true,
            };
        case APPEND_WEBPRAISAL_VALUE:
            const appendItems = [
                ...state[action.payload.index],
            ];
            appendItems.push(action.payload.value);
            let returnAppend = { ...state };
            returnAppend[action.payload.index] = appendItems;
            return returnAppend;
        case SET_WEBPRAISAL_VALUE:
            let returnValue = { ...state };
            returnValue[action.payload.index] = action.payload.value;
            return returnValue;
        default:
            return {
                ...state,
            }
    }
}

export default WebpraisalReducer;