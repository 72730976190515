import { fork, all, takeLeading, select, call, put, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
    SUBSCRIBE_WITH_PAYMENT,
    setUser,
    setTransition,
    setRegistrationStep,
} from "../../store/Actions";

import {
    fetchRegistrationStep1,
    fetchRegistrationStep2,
} from "../../api/Api";

const selectGmailClient = (state) => state.userSlice.client;

function* getSubscribeWithPayment({payload}) {
    try {
        yield put(setTransition(true));
        yield delay(500);

        yield put(setTransition(false));
        yield put(setRegistrationStep(2));
        
        const gmailClient = yield select(selectGmailClient);
        //Use gmail client to verify user for payment

        yield call(fetchRegistrationStep1, {
            paymentMethod: payload.paymentMethod,
            client: gmailClient,
            subscription: payload.subscription,
        });

        yield put(setTransition(true));
        yield delay(500);

        yield put(setTransition(false));
        yield put(setRegistrationStep(3));
        const paymentResult = yield call(fetchRegistrationStep2, { client: gmailClient });
        let user = paymentResult.data.profile;
        yield put(setUser(user));

        yield put(setTransition(true));
        yield delay(500);
        toast.success("Logging In!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        
    } catch (error) {
        
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* watchDataSaga() {
    yield takeLeading(SUBSCRIBE_WITH_PAYMENT, getSubscribeWithPayment);
}

export default function* RgistrationSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}