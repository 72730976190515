import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <>        
            <footer className="bg-white w-full pt-16">
                <div className="max-w-7xl m-auto text-left bg-white grid grid-cols-6 px-10">
                    <div className="flex flex-col">
                        <div className="font-bold pb-3">Respond Pilot</div>
                        <Link to="/">Home</Link>
                        <Link to="contact">Contact Us</Link>
                        <Link>Blog</Link>
                    </div>
                    <div className="flex flex-col">
                        <div className="font-bold pb-3">Account</div>
                        <Link to="/login">Create Account</Link>
                        <Link to="/login">Sign In</Link>
                    </div>
                    <div className="flex flex-col">
                        <div className="font-bold pb-3">Terms</div>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-of-service">Terms Of Service</Link>
                    </div>
                    <div className="col-span-6 flex flex-row pt-10 pb-10">© Copyright by&nbsp;<b> Respond Pilot</b></div>
                </div>
            </footer>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
