import { createSlice  } from '@reduxjs/toolkit';

export const UiSlice = createSlice({
    name: "ui",
    initialState: {
        transition: false,
        registrationStep: 1,
    },
    reducers: {
        setRegistrationStep: (state, action) => {
            state.registrationStep = action.payload;
        },
        setTransition: (state, action) => {
            state.transition = action.payload;
        }
    },
});

export const { 
    setRegistrationStep,
    setTransition,
} = UiSlice.actions;
export const selectUI = (state) => state.uiSlice;
export default UiSlice.reducer;
