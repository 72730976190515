import { all, fork, takeLeading, takeEvery, put, call, delay, select } from "redux-saga/effects";
import { toast } from 'react-toastify';

import {
    getEmail,
    getEmails,
    getRedirectUrl,
    getEmailDraft,
    getGenerateAiResponse,
    getRefresh,
    clearEmails,
    setEmail,
    setEmails,
    setRedirectUrl,
    setEmailStatusAndResponseById,
    setEmailStatusById,
    selectEmails,
    selectUser,
    setUserTokens,
    setEmailToRead,
    logout,
} from "../../store/Actions";

import {
    fetchEmail,
    fetchEmails,
    fetchEmailsRedirectUrl,
    fetchEmailCreateDraft,
    fetchAiResponseCreate,
    fetchAiResponseThread,
    fetchEmailUpdateStatusToRead,
} from "../../api/Api";

const selectGmailClient = (state) => state.userSlice.client;

function* handleGetEmail({payload}) {
    try {
        let emails = yield select(selectEmails);
        const gmailClient = yield select(selectGmailClient);
        const emailIndex = payload;
        let email = yield call(fetchEmail, {
            client: gmailClient,
            email: emails[emailIndex],
        });
        yield put(setEmail({ index: emailIndex, value: email.data }));
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* handleGetEmails({ payload }) {
    try {
        // Limit to 100 for now
        let emails = yield select(selectEmails);
        if (Object.keys(emails).length !== 0) return;
        const gmailClient = yield select(selectGmailClient);
        const emailsResponse = yield call(fetchEmails, { client: gmailClient });
        yield put(setEmails(emailsResponse.data.emails));
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* handleRedirectUrl() {
    try {        
        const redirectUrl = yield call(fetchEmailsRedirectUrl);
        yield put(setRedirectUrl(redirectUrl.data));
    } catch (error) {
        console.log(error.message);
    }
}

function* handleCreateDraft({payload}) {
    try {
        if (payload.email.response === "") throw new Error("Email draft cannot be empty");
        toast.info('Creating Draft Email', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        yield put(setEmailStatusById({ id: payload.email.emailId, status: "loading" }));
        const gmailClient = yield select(selectGmailClient);
        yield call(fetchEmailCreateDraft, {
            email: payload.email,
            response: payload.response,
            client: gmailClient,
        });
        yield put(setEmailStatusById({ id: payload.email.emailId, status: "draft sent" }));
        toast.success('Draft Email Created', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* handleGenerateAiResponse({payload}) {
    try {
        if (payload.content === "") throw new Error("Email body cannot be empty");
        let user = yield select(selectUser);
        if (user.tokens <= 0) throw new Error("Not enough tokens to make a request");
        yield put(setUserTokens(parseInt(user.tokens) - 1));
        toast.info('Generating AI Response', {
            position: "top-center",
            autoClose: 30000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        yield put(setEmailStatusById({ id: payload.emailId, status: "loading repsonse" }));
        const client = yield select(selectGmailClient);
        const threadResponse = yield call(fetchAiResponseCreate,  {
            client: client,
            email: payload,
        });
        yield delay(10000);
        let queueResponse = yield call(fetchAiResponseThread, {
            client: client,
            ...threadResponse.data,
        });

        while (queueResponse.data.status === "in_progress") {
            yield delay(10000);
            queueResponse = yield call(fetchAiResponseThread, {
                client: client,
                ...threadResponse.data,
            });
        }
        yield put(setEmailStatusAndResponseById({ 
            id: payload.emailId, 
            status: "ai response generated",
            response: queueResponse.data.data, 
        }));
        toast.success('AI Response Generated', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        console.log(error.message);
    }
}

function* handleLogout() {
    yield put(clearEmails());
}

function* handleRefresh() {
    yield put(setEmails([]));
    yield put({ type: getEmails.type });
}

function* handleSetEmailToRead({payload}) {
    try {        
        const client = yield select(selectGmailClient);
        yield put(setEmailStatusById({ id: payload.emailId, status: "read" }));
        yield call(fetchEmailUpdateStatusToRead,  {
            client: client,
            emailId: payload.emailId,
        });
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* watchDataSaga() {
    yield takeEvery(getEmail.type, handleGetEmail);
    yield takeLeading(getEmails.type, handleGetEmails);
    yield takeLeading(getRedirectUrl.type, handleRedirectUrl);
    yield takeLeading(getEmailDraft.type, handleCreateDraft);
    yield takeEvery(getGenerateAiResponse.type, handleGenerateAiResponse);
    yield takeLeading(logout.type, handleLogout);
    yield takeLeading(getRefresh.type, handleRefresh);
    yield takeLeading(setEmailToRead.type, handleSetEmailToRead);
}

export default function* dataSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}
