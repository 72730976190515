import React, { useCallback, useEffect} from "react";
import { Link } from  "react-router-dom";
import { connect } from "react-redux";

import {
    getListFiles,
} from "./../store/Actions";

import FilesTable from "../components/files/FilesTable";

function Files({
    doGetListFiles,
    files,
    userTokens,
    closeModalOnClick,
}) {
    const memoizedDoGetListFiles = useCallback(() => {
        doGetListFiles();
    }, [doGetListFiles]);

    useEffect(() => {
        memoizedDoGetListFiles()
    }, [memoizedDoGetListFiles]);

    return (
        <div className="flex flex-col" onClick={e => e.stopPropagation()}>
            <div className="grid grid-cols-2 px-6">
                <div className="flex flex-row py-2">
                    <img className="h-8" src="/images/logo_tilt.png" alt="respond pilot logo of a email smiling"/>
                    <div className="font-semibold pl-2 pt-2 text-center">Add Tokens or Cancel Subscription</div>
                </div>
                <div className="justify-self-end py-3">                    
                    <button className="" onClick={closeModalOnClick}>
                        <img className="h-5" src="/images/exit.svg" alt="X for exit modal"/>
                    </button>
                </div>
            </div>
            <div className="h-px w-full bg-gray-500/50"></div>
            <div className="px-4 py-3 flex flex-col">
                <div className="pb-8">Total Tokens: {userTokens}</div>
                <div className="flex flex-row">
                    To add tokens or cancel your subscription, visit Respond Pilot's&nbsp; 
                    <div className="underline text-blue-500">
                        <Link to="https://billing.stripe.com/p/login/test_3cs5ol6iocaQ0pO288" target="_blank">Stripe Customer Portal</Link>
                    </div>.
                </div>
            </div>
            <div className="h-px w-full bg-gray-500/50"></div>
            <div className="grid grid-cols-2 px-6 py-2">
                <div className="flex flex-row">
                    <img className="h-8" src="/images/logo_tilt.png" alt="respond pilot logo of a email smiling"/>
                    <div className="font-semibold text-center pl-2 pt-2">Knowledge Base</div>
                </div>
            </div>
            <div className="h-px w-full bg-gray-500/50"></div>
            <div className="py-3 pb-8 px-8">
                <div className="px-4 pt-5">{ files.length } of 5 files uploaded</div>
                <div className="px-4 pb-5">
                    Only .txt files can be uploaded. Max limit of 100 kb per file.<br/>
                    Contact us for assistance. 
                </div>
                <FilesTable/>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doGetListFiles: (payload) => dispatch(getListFiles(payload)),
});

const mapStateToProps = (state) => ({
    files: state.fileSlice.files,
    userTokens: state.userSlice.user.tokens,
});

export default connect(mapStateToProps, mapDispatchToProps)(Files);
