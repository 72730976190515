import { put, call, fork, all, takeEvery, takeLeading, takeLatest, select } from 'redux-saga/effects';
import {
    FIREBASE_GET_CUSTOM_TOKEN,
    FIREBASE_GET_SETTINGS,
    FIREBASE_UPDATE_SETTING,
    FIREBASE_COLLECT_EMAIL,
    FIREBASE_CONTACT_US,
    FIREBASE_GET_AVERAGE_TIME_UPDATE,
    FIREBASE_ANALYTICS,
    FIREBASE_ADMIN_REQUESTS,
    FIREBASE_ADMIN_REQUEST,
    FIREBASE_ADMIN_GET_DOCUMENTS,
    firebaseSetValue,
} from "../../store/Actions";

import {
    fetchFirebaseGetCustomToken,
    fetchGetFirebaseSettings,
    fetchFirebaseSetSetting,
    fetchFirebaseCollectEmail,
    fetchGetFirebaseAvaregeTimeUpdate,
    fetchGetFirebaseAnalytics,
    fetchGetFirebaseGetRequests,
    fetchGetFirebaseGetRequest,
    fetchGetFirebaseGetDocuments,
} from "../../api/Api";

import {
    setUserToken,
} from "./../reducers/UserSlice";

const selectUserToken = (state) => state.userSlice.customToken;

function* getFirebaseGetCustomToken(data) {
    const token = yield call(fetchFirebaseGetCustomToken, data.payload);
    yield put(setUserToken(token.data.customToken));
}

function* firebaseGetSettings() {
    const settings = yield call(fetchGetFirebaseSettings);
    yield put(firebaseSetValue({index: "settings", value: settings.data.data}));
}

function* firebaseSetSetting({payload}) {
    yield call(fetchFirebaseSetSetting, payload);
}

function* firebaseCollectEmail({payload}) {
    yield put(firebaseSetValue({
        index: "isLoading",
        value: true,
    }));
    yield call(fetchFirebaseCollectEmail, { email: payload });
    yield put(firebaseSetValue({
        index: "emailSubmitted",
        value: true,
    }));
    yield put(firebaseSetValue({
        index: "isLoading",
        value: true,
    }));
}

function* firebaseContactUs({payload}) {
    yield call(fetchFirebaseCollectEmail, { ...payload });
}

function* getFirebaseAvaregeTimeUpdate() {
    const userToken = yield select(selectUserToken);
    const newAverage = yield call(fetchGetFirebaseAvaregeTimeUpdate, {userToken});
    yield put(firebaseSetValue({
        index: "averageTimeToComplete",
        value: newAverage.data.data,
    }));
}

function* getFirebaseAnalytics() {
    const userToken = yield select(selectUserToken);
    const analytics = yield call(fetchGetFirebaseAnalytics, {userToken});
    yield put(firebaseSetValue({
        index: "analytics",
        value: analytics.data.data,
    }));
}

function* firebaseAdminRequests ({ payload }) {
    const requests = yield fetchGetFirebaseGetRequests(payload);
    yield put(firebaseSetValue({
        index: "requests",
        value: requests.data,
    }));
}

function* firebaseAdminGetDocuments ({ payload }) {

    const documents = yield fetchGetFirebaseGetDocuments(payload);
    yield put(firebaseSetValue({
        index: payload.documents,
        value: documents.data,
    }));
}

function* firebaseAdminRequest ({ payload }) {
    yield console.log("payload",payload);
    const requests = yield fetchGetFirebaseGetRequest(payload);
    yield put(firebaseSetValue({
        index: "request",
        value: requests.data.request,
    }));
}


function* watchDataSaga() {
    yield takeLatest(FIREBASE_GET_CUSTOM_TOKEN, getFirebaseGetCustomToken);
    yield takeEvery(FIREBASE_GET_SETTINGS, firebaseGetSettings);
    yield takeLeading(FIREBASE_UPDATE_SETTING, firebaseSetSetting);
    yield takeLeading(FIREBASE_COLLECT_EMAIL, firebaseCollectEmail);
    yield takeLeading(FIREBASE_GET_AVERAGE_TIME_UPDATE, getFirebaseAvaregeTimeUpdate);
    yield takeLeading(FIREBASE_ANALYTICS, getFirebaseAnalytics);
    yield takeLeading(FIREBASE_CONTACT_US, firebaseContactUs);
    yield takeLeading(FIREBASE_ADMIN_REQUESTS, firebaseAdminRequests);
    yield takeLeading(FIREBASE_ADMIN_REQUEST, firebaseAdminRequest);
    yield takeLeading(FIREBASE_ADMIN_GET_DOCUMENTS, firebaseAdminGetDocuments);
}

export default function* FirebaseSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}