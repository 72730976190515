import { fork, all } from 'redux-saga/effects';
import {

} from "../../store/Actions";

import {

} from "../../api/Api";


function* watchDataSaga() {
}

export default function* RgistrationSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}