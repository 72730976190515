import React, { useEffect, useCallback } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
    authLogout,
    firebaseGetCustomToken,
} from '../../store/Actions';


function AdminLayout({ 
    doAuthLogout,
    doFirebaseGetCustomToken,
    state,
    user,
    children,
}) {
    const memoizedDoFirebaseGetCustomToken = useCallback(() => {
        doFirebaseGetCustomToken(user.user.uid);
    }, [doFirebaseGetCustomToken, user.user.uid])

    useEffect(() => {
        memoizedDoFirebaseGetCustomToken();
    }, [memoizedDoFirebaseGetCustomToken]);

    const handleLogout = () => {
        doAuthLogout();
    }

    return (
        <div className="bg-blue-200/50 min-h-screen flex flex-row">
            <div className="flex flex-col bg-blue-300/50 w-60 h-screen float-left text-center fixed">
                <div className="w-full pl-3 pb-2 bg-stone-400 text-lg flex flex-row">
                    <div className="mt-3">Price Chant</div>
                </div>
                <div className="pt-4 flex flex-col bg-stone-300 shadow-lg h-full relative">
                    <Link to="/admin" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Home</Link>
                    <Link to="/admin/requests" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Requests</Link>
                    <Link to="/admin/messages" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Messages</Link>
                    <Link to="/admin/settings" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Settings</Link>
                    <Link to="/admin/graphs" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Graphs</Link>
                    <Link to="/admin/price-chant" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">PriceChant</Link>
                    <Link to="/client/" className="bg-indigo-200 hover:bg-indigo-500/80 p-2 m-2">Client Panel</Link>
                    <button className="bg-indigo-200 hover:bg-indigo-500/80 bottom-0 absolute w-full h-10" onClick={handleLogout}>Logout</button>
                </div>
            </div>

            <div className="bg-gray-200 w-full pl-60">
                { children }
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    doAuthLogout: () => dispatch(authLogout()),
    doFirebaseGetCustomToken: (uuid) => dispatch(firebaseGetCustomToken(uuid)),
});

const mapStateToProps = (state) => ({
    state: state.firestore,
    user: state.userSlice.user,
    token: state.userSlice.customToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);