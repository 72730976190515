import { createSlice, createAction  } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

const rehydrateAction = createAction(REHYDRATE);

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            tokens: 0,
        },
        client: {},
        customToken: null,
        registrationStep: 1,
    },
    reducers: {
        login: () => {},
        logout: (state) => {
            state.user = {};
            state.client = {};
        },
        setRegistrationStep: (state, action) => {
            state.registrationStep = action.payload;
        },
        setUserTokens: (state, action) => {
            state.user.tokens = action.payload;
        },
        setUserToken: (state, action) => {
            state.customToken = action.payload;
        },
        clearUserData: (state, action) => {
            state.user = {};
            state.client = {};
        },
        setClient: (state, action) => {
            state.client = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        verifyTokenOrInvalidate: () => {},
        setEmailClientAndUserProfile: () => {},
    },
    extraReducers: (builder) => {
        builder.addCase(rehydrateAction, (state, action) => {
            return { ...state, ...(action.payload?.userSlice || {}) };
        });
    },
});

export const { 
    setUserToken, 
    setUserTokens,
    setClient,
    setUser,
    clearUserData, 
    login, 
    logout, 
    verifyTokenOrInvalidate,
    setEmailClientAndUserProfile,
} = UserSlice.actions;
export const selectUser = (state) => state.userSlice.user;
export const selectToken = (state) => state.userSlice;
export default UserSlice.reducer;
