import React, { useEffect, useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import EmailTable from "../components/emails/EmailTable";
import OpenEmail from "../components/emails/OpenEmail";

import {
    getEmails,
} from "./../store/Actions";

function Emails({
    doGetEmails,
    user,
}) {
    const [ exitCancel, setExitCancel ] = useState(false);
    const memoizedDoGetEmails = useCallback(() => {
        doGetEmails()
    }, [doGetEmails]);

    useEffect(() => {
        memoizedDoGetEmails();
    }, [memoizedDoGetEmails]);

    return (
        <>
            { (user.status === "cancelled" && !exitCancel) &&
                <div className="max-w-7xl m-auto m-auto bg-primary-red/80 rounded mt-2 p-2 text-center text-xl">
                    <button className="float-right" onClick={() => setExitCancel(true)}>
                        <img className="h-5" src="/images/exit.svg" alt="X for exit modal"/>
                    </button>
                    <div>
                    Your journey with Respond Pilot may have paused, but we're always here to welcome you back! <br/>
Simply <u><Link to="https://billing.stripe.com/p/login/test_3cs5ol6iocaQ0pO288" target="_blank">click here</Link></u> to reactivate your account and rejoin the future of effortless email management.
                    </div>
                </div>
            }
            <div className="max-w-7xl m-auto flex flex-row min-w-fit pt-4">
                <EmailTable/>
                <OpenEmail/>
            </div>
            <div className="bg-white h-10 mt-16">
                <img src="/images/paper-tear-top.svg" alt="Description"/>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doGetEmails: payload => dispatch(getEmails(payload)),
});

const mapStateToProps = (state) => ({
    emails: state.emailSlice.emails,
    user: state.userSlice.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Emails);
