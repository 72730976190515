import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import AdminLayout from "../../components/admin/Layout";
import {
    firebaseAdminGetDocuments,
} from "../../store/Actions";

const AdminMessages = ({
    doFirebaseAdminDocuments,
    userToken,
    emails,
    hasPreviousPage,
    hasMore,
}) => {
    const { id, cursorType } = useParams();

    const memoizedDoFirebaseAdminDocuments = useCallback(() => {
        let slugToUse = "none";
        if (typeof id !== "undefined") {
            slugToUse = id;
        }

        let cursorTypeToUse = "startAfter";
        if (typeof cursorType !== "undefined") {
            cursorTypeToUse = cursorType;
        }
        doFirebaseAdminDocuments({
            documents: "emails",
            limit: 10,
            cursorEntity: slugToUse,
            cursorType: cursorTypeToUse,
            userToken: userToken,
        });
    }, [doFirebaseAdminDocuments, id, userToken, cursorType]);

    useEffect(() => {
        memoizedDoFirebaseAdminDocuments()
    }, [memoizedDoFirebaseAdminDocuments]);

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">
                emails
            </div>
            <div className="flex flex-col max-w-4xl p-5">
                <div className="p-10">
                    <table className="table-auto bg-slate-400 text-center">
                        <thead className="bg-slate-500 text-lg">
                            <tr>
                                <th>id</th>
                                <th className="w-96">created</th>
                                <th className="w-96">email</th>
                                <th className="w-96">message (optional)</th>
                            </tr>
                        </thead>
                        <tbody className="text-xl">
                            {
                                emails.map((request) => {
                                    const date = new Date(request.created.seconds * 1000);
                                    const displayDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                                    return (
                                        <tr className="hover:bg-slate-300" key={request.id}>
                                            <td>{ request.id.substring(0, 5) }...</td>
                                            <td className="">{ displayDate }</td>
                                            <td className="">{ request.email }</td>
                                            <td className="">{ request?.message }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="grid grid-cols-2 gap-10 text-center pt-5">
                        { hasPreviousPage &&
                            <Link to={`/admin/requests/${hasPreviousPage}/previous`} className="bg-white hover:bg-indigo-100 border-2 border-indigo-200 m-2 p-2">Previous</Link>
                        }
                        { hasMore &&
                            <Link to={`/admin/requests/${hasMore}/next`} className="bg-white hover:bg-indigo-100 border-2 border-indigo-200 m-2 p-2 col-start-2">Next</Link>
                        }
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseAdminDocuments: (data) => dispatch(firebaseAdminGetDocuments(data)),
});

const mapStateToProps = (state) => ({
    emails: state.firebase.emails.edges,
    hasMore: state.firebase.emails.hasMore,
    hasPreviousPage: state.firebase.emails.hasPreviousPage,
    userToken: state.userSlice.customToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMessages);