import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import SchedulerButton from "../scheduler/SchedulerButton";
import ContentBox from "../ui/ContentBox";
import Files from "../../pages/Files";
import {
    getGenerateAiResponse,
    getEmailDraft,
    setEmailToRead,
} from "../../store/Actions";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" onClick={onClose}>
            <ContentBox className="relative top-20 mx-auto max-w-3xl shadow-lg bg-white">
                {children}
            </ContentBox>
        </div>
    );
};

function OpenEmail({
    doGetEmailCreateDraft,
    doGetGenerateAiResponse,
    doSetEmailToRead,
    emails,
    emailOpenIndex,
    userTokens,
}) {
    const [ currentEmail, setCurrentEmail ] = useState({});
    const handleOnClickResponse = () => doGetGenerateAiResponse(currentEmail);
    const handleOnClickDraft = () => doGetEmailCreateDraft({email: currentEmail, response: currentEmail.response});
    const [ fromArray, setFromArray ] = useState(["",""]);
    useEffect(() => {
        emails.map((email, index) => {
            if (index === emailOpenIndex) {
                if (email.status === "unread") {
                    doSetEmailToRead(email);
                }
                if (email.from === null || email.from === undefined || email.from === "") {
                    setFromArray(["",""]);
                } else {
                    setFromArray(email.from.split(" <"));
                }
                setCurrentEmail(email);
            }
            return email;
        });
    }, [emailOpenIndex, emails, currentEmail, setFromArray, setCurrentEmail, doSetEmailToRead]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <>
        <div className="md:w-[450px] md:min-h-[600px] h-full">
            <ContentBox className="mb-3 px-4 py-2 flex flex-row justify-between bg-white">
                <div className="flex flex-row pt-2">
                    <img className="h-7 pr-2 -mt-1" src="/images/logo_tilt.png" alt="Email with a face and flying hat"/>
                    <div className="font-semibold">Tokens: {userTokens}</div>
                </div> 
                
                <div className="px-2">
                    <img onClick={openModal} className="h-10 hover:cursor-pointer transition hover:rotate-45 float-right" src="/images/cog-icon.png" alt="cog for the setting panel"/>
                </div>
            </ContentBox>
            <ContentBox className="mb-3 bg-white">
                <div className="font-medium px-4 text-base border-b h-10 justify-center flex flex-col">
                    Message
                </div>
                <div className="m-2">
                    <div className="justify-self-start">
                        <span className="text-base">{ fromArray[0] }</span><br/>
                        <hr/>
                        Status: { currentEmail?.status }<br/>
                        Subject: { currentEmail?.subject }<br/>
                    </div>
                    <textarea value={ currentEmail.content } disabled={true} className="w-full ring-1 ring-gray-border/20 rounded-md h-48 p-2"/>
                </div>
            </ContentBox>
            <ContentBox className="bg-white">
                <div className="font-medium px-4 py-2 h-10 text-base border-b">
                    Respond Pilot
                </div>
                <div className="m-2">
                    <textarea value={ currentEmail?.response } disabled={true} className="w-full ring-1 ring-gray-border/20 rounded-md h-48 p-2"/>
                    <div className="grid grid-cols-2 mt-2">
                        {Object.keys(currentEmail).length !== 0 &&
                            <>
                                <div onClick={handleOnClickResponse}>
                                    <SchedulerButton to="">
                                        <img className="w-10" src="/images/generate-reply-icon-hover.png" alt="Generate ai response icon"/>
                                        <div className="m-auto">Generate Reply</div>
                                    </SchedulerButton>
                                </div>
                                <div onClick={handleOnClickDraft}>
                                    <SchedulerButton to="">
                                        <img className="w-10" src="/images/send-draft-hover.png" alt="Send draft icon"/>
                                        <div className="m-auto">Save Draft</div>
                                    </SchedulerButton>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </ContentBox>
        </div>

        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <Files closeModalOnClick={closeModal}/>
        </Modal>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doGetGenerateAiResponse: payload => dispatch(getGenerateAiResponse(payload)),
    doGetEmailCreateDraft: payload => dispatch(getEmailDraft(payload)),
    doSetEmailToRead: payload => dispatch(setEmailToRead(payload)),
});

const mapStateToProps = (state) => ({
    emails: state.emailSlice.emails,
    emailOpenIndex: state.emailSlice.emailOpenIndex,
    userTokens: state.userSlice.user.tokens,
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenEmail);