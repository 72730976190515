import React, { useCallback, useEffect } from 'react';
import { connect } from "react-redux";
import { verifyTokenOrInvalidate } from "./../store/reducers/UserSlice";

const GuestGuard = ({ 
    children,
    doVerifyTokenOrInvalidate,
 }) => {
    const memoizedVerifyClientOrInvalidate = useCallback(() => {
        doVerifyTokenOrInvalidate();
    }, [doVerifyTokenOrInvalidate]);

    useEffect(() => {
        memoizedVerifyClientOrInvalidate();
    }, [memoizedVerifyClientOrInvalidate]);

    return <div>{children}</div>;
};

const mapDispatchToProps = (dispatch) => ({
    doVerifyTokenOrInvalidate: payload => dispatch(verifyTokenOrInvalidate()),
});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(GuestGuard);
