export const FIREBASE_GET_SETTINGS = "FIREBASE_GET_SETTINGS";
export const FIREBASE_UPDATE_SETTING = "FIREBASE_UPDATE_SETTING";
export const FIREBASE_SET_VALUE = "FIREBASE_SET_VALUE";
export const FIREBASE_COLLECT_EMAIL = "FIREBASE_COLLECT_EMAIL";
export const FIREBASE_CONTACT_US = "FIREBASE_CONTACT_US";
export const FIREBASE_GET_AVERAGE_TIME_UPDATE = "FIREBASE_GET_AVERAGE_TIME_UPDATE";
export const FIREBASE_ANALYTICS = "FIREBASE_ANALYTICS";
export const FIREBASE_ADMIN_REQUESTS = "FIREBASE_ADMIN_REQUESTS";
export const FIREBASE_ADMIN_REQUEST = "FIREBASE_ADMIN_REQUEST";
export const FIREBASE_ADMIN_GET_DOCUMENTS = "FIREBASE_ADMIN_GET_DOCUMENTS";

export const firebaseAdminGetDocuments = (data) => ({
    type: FIREBASE_ADMIN_GET_DOCUMENTS,
    payload: data,
});

export const firebaseAdminRequest = (data) => ({
    type: FIREBASE_ADMIN_REQUEST,
    payload: data,
});

export const firebaseAdminRequests = (data) => ({
    type: FIREBASE_ADMIN_REQUESTS,
    payload: data,
});

export const firebaseAnalytics = () => ({
    type: FIREBASE_ANALYTICS,
});

export const firebaseGetAverageTimeUpdate = (data) => ({
    type: FIREBASE_GET_AVERAGE_TIME_UPDATE,
    payload: data,
});

export const firebaseContactUs = (data) => ({
    type: FIREBASE_CONTACT_US,
    payload: data,
});

export const firebaseCollectEmail = (data) => ({
    type: FIREBASE_COLLECT_EMAIL,
    payload: data,
});

export const firebaseUpdateSettings = (data) => ({
    type: FIREBASE_UPDATE_SETTING,
    payload: data,
});

export const firebaseGetSettings = () => ({
    type: FIREBASE_GET_SETTINGS,
});

export const firebaseSetValue = (data) => ({
    type: FIREBASE_SET_VALUE,
    payload: data,
})

export const FIREBASE_GET_CUSTOM_TOKEN = "FIREBASE_GET_CUSTOM_TOKEN";
export const FIREBASE_GET_POSTS = "FIREBASE_GET_POSTS";
export const FIREBASE_CREATE_COLLECTION = "FIREBASE_CREATE_COLLECTION";
export const FIREBASE_UPDATE_COLLECTION = "FIREBASE_UPDATE_COLLECTION";
export const FIREBASE_DELETE_COLLECTION = "FIREBASE_DELETE_COLLECTION";
export const FIREBASE_GET_COLLECTION = "FIREBASE_GET_COLLECTION";

export const firebaseGetPosts = (data) => ({
    type: FIREBASE_GET_POSTS,
    payload: data,
});

export const firebaseGetCustomToken = (data) => ({
    type: FIREBASE_GET_CUSTOM_TOKEN,
    payload: data,
});

export const firebaseCreateCollection = (data) => ({
    type: FIREBASE_CREATE_COLLECTION,
    payload: data,
});

export const firebaseUpdateCollection = (data) => ({
    type: FIREBASE_UPDATE_COLLECTION,
    payload: data,
});

export const firebaseDeleteCollection = (data) => ({
    type: FIREBASE_DELETE_COLLECTION,
    payload: data,
});

export const firebaseGetCollection = (data) => ({
    type: FIREBASE_GET_COLLECTION,
    payload: data,
});

