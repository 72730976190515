export const INIT_LOGIN = "INIT_LOGIN";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const authLogout = () => ({
    type: AUTH_LOGOUT,
});

export const authLogin = (data) => ({
    type: AUTH_LOGIN,
    payload: data,
});
