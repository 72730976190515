import React from "react";
import { connect } from "react-redux";

import CheckoutForm from "../../components/stripe/CheckoutForm";

import {

} from "../../store/Actions";

function Registration() {
    return (
        <>
        <div className="max-w-7xl grid grid-cols-3 gap-10 m-auto pt-16 px-10">
            <div className="col-span-1 flex flex-col bg-white rounded-lg">
                <div className="p-6">
                    <div className="">
                        <img className="h-14 " src="/images/launch-icon.svg" alt="Drawing of a rocket launching"/>
                    </div>
                    <div className="pt-4 font-extrabold text-2xl">One Pricing Plan</div>
                    <div className="pt-4 font-medium text-base">No Upsell</div>
                </div>
                <div className="h-px w-full bg-gray-500/50"></div>
                <div className="p-6">
                    <div className="font-extrabold text-base">Price:</div>
                    <div className="pt-4 font-extrabold text-2xl">$25.00/month</div>
                    <div className="pt-1 text-base">50 credits added to account every month.</div>
                </div>
                <div className="h-px w-full bg-gray-500/50"></div>
                <div className="pt-8 pl-6">
                    <div className="font-extrabold text-base">How if works:</div>
                    <div className="pt-3 font-extrabold text-2xl">1 Credit = 1 AI Response</div>
                    <div className="pt-3 text-base">Auto recurring for convenience, but cancel anytime. No questions asked.</div>
                </div>
            </div>

            <div className="col-span-2 flex flex-col bg-white rounded-lg p-8">
                <div className="pt-2 pb-5 font-extrabold text-3xl text-center">Almost there!</div>
                <CheckoutForm subscriptionOption={1}/>
                <img className="pt-10" src="/images/Powered_By_Stripe.png" alt="Powered by stripe"/>
            </div>
        </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);


