import { all, fork, takeLeading, call } from "redux-saga/effects";

import {
    TRACK_EVENT,
} from "../../store/Actions";
import ReactGA from "react-ga4";

function* handleTrackEvent(action) {
    const { eventName, category } = action.payload;
    yield call(ReactGA.event({
        category: category,
        action: eventName,
    }));
}

function* watchDataSaga() {
    yield takeLeading(TRACK_EVENT, handleTrackEvent);
}

export default function* analyticsSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}
