import { createSlice } from '@reduxjs/toolkit';

export const FileSlice = createSlice({
    name: "file",
    initialState: {
        files: [],
    },
    reducers: {
        setFiles: (state, action) => { state.files = action.payload },
        addFilePending: (state, action) => {
            const { name } = action.payload;
            state.files.push({
                name: name,
                status: "uploading",
                created: {
                    seconds: new Date().getTime() / 1000,
                }
            })
        },
        removeFilePending: (state, action) => {
            const { name } = action.payload;
            state.files = state.files.filter(file => file.name !== name && file.status !== "uploading");
        },
        removeFileUpdateStatus: (state, action) => {
            const fileId = action.payload;
            state.files.forEach(file => {
                if (file.fileId === fileId) {
                    file.status = "deleting";
                }
            });
        },
        getUploadFile: () => {},
        getRemoveFile: () => {},
        getListFiles: () => {},
    },
});

export const { 
    setFiles,
    addFilePending,
    removeFilePending,
    removeFileUpdateStatus,
    getListFiles,
    getUploadFile,
    getRemoveFile,
} = FileSlice.actions;

export const selectFiles = (state) => state.fileSlice.files;

export default FileSlice.reducer;
