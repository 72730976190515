import React, { useEffect } from "react";
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";

import AuthGuard from "./guards/AuthGuard";
import GuestGuard from "./guards/GuestGuard";

import Layout from "./components/layout/Layout";

import Home from "./pages/Home";
import Registration from "./pages/Registration";

import Emails from "./pages/Emails";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Login from "./pages/Login";
import NotFound404 from "./pages/NotFound404";

// import Login from "./pages/auth/Login";

import Admin from "./pages/admin/Admin";
import AdminRequests from "./pages/admin/Requests";
import AdminRequest from "./pages/admin/Request";
import AdminMessages from "./pages/admin/Messages";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-VM5RWTGKD2";

function App() {
    const location = useLocation();
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
    }, [location]);
    return (
        <Layout>
            <Routes location={location}>
                <Route exact path="/" element={<GuestGuard><Home /></GuestGuard>} />
                <Route exact path="/contact" element={<GuestGuard><Contact /></GuestGuard>} />
                <Route exact path="/privacy-policy" element={<GuestGuard><PrivacyPolicy /></GuestGuard>} />
                <Route exact path="/terms-of-service" element={<GuestGuard><TermsOfService /></GuestGuard>} />
                <Route exact path="/login" element={<Login />} />

                <Route exact path="/registration" element={<Registration />} />

                <Route exact path="/emails" element={<AuthGuard><Emails /></AuthGuard>} />

                <Route path="/admin">
                    <Route path="requests">
                        <Route exact path=":id/:cursorType" element={<AuthGuard><AdminRequests/></AuthGuard>} />
                        <Route exact path="" element={<AuthGuard><AdminRequests/></AuthGuard>} />
                    </Route>
                    <Route path="messages">
                        <Route exact path="" element={<AuthGuard><AdminMessages/></AuthGuard>} />
                    </Route>
                    <Route exact path="request/:id" element={<AuthGuard><AdminRequest/></AuthGuard>} />
                    <Route exact path="" element={<AuthGuard><Admin/></AuthGuard>} />
                </Route>

                <Route path="*" element={<NotFound404 />} />
            </Routes>
        </Layout>
    );
}

export default App;
