import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from "redux-logger";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../store/Reducers';
import rootSaga from '../store/RootSaga';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userSlice", "firebase", "webpraisal", "emailSlice"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    applyMiddleware(sagaMiddleware, logger)
);

sagaMiddleware.run(rootSaga);

export default store;