import React, { useState } from "react";

function BouncyPaginationButton({
    children,
    handleOnClick,
    isCurrent,
    key,
}) {
    const [activeClass, setActiveClass] = useState("");

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }
    return (
        <div key={key} className="text-center bouncy-button-container relative w-fit hover:cursor-pointer h-11 z-20"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseDown={handleClick}
            onMouseUp={handleUnclick}
        >
            <div className="absolute w-full bg-gray-600 h-11 -z-10 rounded-lg"></div>
            <button type="submit" className={`ring-1 ring-black rounded-lg text-black
                text-xl py-2 px-4 bouncy-button z-10 w-fit font-semibold bg-white ${activeClass} ${isCurrent ? " bouncy-button-in" : ""}`}
                onClick={handleOnClick}>
                { children }
            </button>
        </div>
    )
}

export default BouncyPaginationButton;
