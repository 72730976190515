import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";

import EmailStatus from "./EmailStatus";

import {
    getGenerateAiResponse,
    getEmail,
    setOpenEmailIndex,
    getEmailDraft,
} from "../../store/Actions";

function Scheduler({
    item,
    index,
    indexStart,
    doGetEmailCreateDraft,
    doGetGenerateAiResponse,
    emailOpenIndex,
    doGetEmail,
    doSetOpenEmailIndex,
}) {
    const handleOnClickResponse = () => doGetGenerateAiResponse(item);
    const handleOnClickDraft = () => doGetEmailCreateDraft({email: item, response: item.response});
    // Change to slice here
    const handleOnClickView = () => doSetOpenEmailIndex(indexStart);

    const [ fromArray, setFromArray ] = useState(["",""]);
    const [ displayDate, setDisplayDate ] = useState("");

    const memoizedDoGetEmail = useCallback(() => {
        if (item?.status === "new") {
            doGetEmail(indexStart);
            // console.log('Call this function to load by index', indexStart);
        }
    }, [doGetEmail, item?.status, indexStart]);

    useEffect(() => {
        memoizedDoGetEmail();
    }, [memoizedDoGetEmail])

    useEffect(() => {
        if (item?.status !== "new") {
            const date = new Date(item.created / 1);
            setDisplayDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);

            setFromArray(item.from.split(" <"));
        }
    }, [item, setDisplayDate, setFromArray])

    return (
    <>
        { (item?.status === "new" || item.status === null)?
            <tr key={indexStart+1000} className={`md:h-[61px] duration-300 hover:cursor-pointer border-b border-b-gray-border/20 w-full ${emailOpenIndex === indexStart ? "green-selected shadow-md" : "hover:bg-green-selected/40 hover:shadow-md" }`} onClick={handleOnClickView}>
                <td className="p-2 text-sm">
                    Loading...
                </td>
                <td className="py-2 text-sm">
                    Loading...
                </td>
                <td className="py-2 text-sm">
                    <div className="bg-gray-100">
                        Loading...
                    </div>
                </td>
                <td className="py-2 text-sm">
                    Loading..
                </td>
                <td className="px-2 py-2 text-sm">
                    
                </td>
            </tr>
        :
            <tr key={indexStart} className={`group/row duration-300 hover:cursor-pointer border-b border-b-gray-border/20 ${emailOpenIndex === indexStart ? "bg-green-selected shadow-md" : "hover:bg-green-selected/40 hover:shadow-md" }`} onClick={handleOnClickView}>
                <td className="px-4 py-1 text-sm text-left">
                    <span className="text-lg font-semibold">{ fromArray[0].slice(0,20) }</span><br/>
                    <span className="text-base font-medium">
                        { typeof fromArray[1] !== "undefined" && 
                            `<${fromArray[1].slice(0,20)}`
                        }
                    </span>
                </td>
                <td className="my-1 text-base text-left font-medium md:w-[380px]">
                    {item.subject.slice(0,100)}
                </td>
                <td className="my-1 text-base text-center font-medium leading-none">
                    <EmailStatus status={item?.status} />
                </td>
                <td className="my-1 text-base text-center font-medium">
                    <div className="group-hover/row:hidden w-28">
                        {displayDate}
                    </div>
                    <div className="justify-center hidden group-hover/row:block">
                       <div className="flex flex-row h-fit">
                            <div onClick={handleOnClickResponse} className="relative group/tooltip-ai ml-5">
                                <img className="w-10 opacity-0 top-o hover:opacity-100 absolute" src="/images/generate-reply-icon-hover.png" alt="Generate ai response icon"/>
                                <img className="w-10 hover:opacity-0" src="/images/generate-reply-icon.png" alt="Generate ai response icon"/>
                                <div className="bg-gray-text text-white font-base p-1 rounded-lg z-50 pointer-events-none absolute -bottom-8 -left-8 w-max opacity-0 transition-opacity group-hover/tooltip-ai:opacity-70 text-sm">
                                    Generate Reply
                                </div>
                            </div>
                            <div className="relative group/tooltip-draft" onClick={handleOnClickDraft}>
                                <img className="w-10 opacity-0 top-o hover:opacity-100 absolute" src="/images/send-draft-hover.png" alt="Generate ai response icon"/>
                                <img className="w-10 hover:opacity-0" src="/images/send-draft.png" alt="Send draft icon"/>
                                <div className="bg-gray-text text-white font-base p-1 rounded-lg z-50 pointer-events-none absolute -bottom-8 -left-3 w-max opacity-0 transition-opacity group-hover/tooltip-draft:opacity-70 text-sm">
                                    Save Draft
                                </div>
                            </div>
                       </div>
                   </div>
                </td>
                
            </tr>
        }
    </>
    );
}
// <td className="px-2 py-2 text-sm">
//     
// </td>
const mapDispatchToProps = (dispatch) => ({
    doGetGenerateAiResponse: payload => dispatch(getGenerateAiResponse(payload)),
    doGetEmail: payload => dispatch(getEmail(payload)),
    doGetEmailCreateDraft: payload => dispatch(getEmailDraft(payload)),
    doSetOpenEmailIndex: payload => dispatch(setOpenEmailIndex(payload)),
});

const mapStateToProps = (state) => ({
    emails: state.emailSlice.emails,
    emailOpenIndex: state.emailSlice.emailOpenIndex,
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);