import { combineReducers } from "redux";

import WebpraisalReducer from "./reducers/WebpraisalReducer";
import UserSlice from "./reducers/UserSlice";
import EmailSlice from "./reducers/EmailSlice";
import FileSlice from "./reducers/FileSlice";
import ContactSlice from "./reducers/ContactSlice";
import FirebaseReducer from "./reducers/FirebaseReducer";
import UiSlice from "./reducers/UiSlice";

const rootReducer = combineReducers({
    webpraisal: WebpraisalReducer,
    firebase: FirebaseReducer,
    userSlice: UserSlice,
    emailSlice: EmailSlice,
    fileSlice: FileSlice,
    contactSlice: ContactSlice,
    uiSlice: UiSlice,
});

export default rootReducer;