import { all, fork } from "redux-saga/effects";

import {

} from "../../store/Actions";

import {

} from "../../api/Api";


function* watchDataSaga() {

}

export default function* dataSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}
