import { all, fork } from "redux-saga/effects";
import dataSaga from "./sagas/dataSagas";
import EmailSaga from "./sagas/EmailSaga";
import FileSaga from "./sagas/FileSaga";
import UserSaga from "./sagas/UserSagas";
import FirebaseSaga from './sagas/FirebaseSaga';
import AnalyticsSaga from "./sagas/AnalyticsSaga";
import RegistrationSaga from "./sagas/RegistrationSaga";
import PaymentSaga from "./sagas/PaymentSaga";
import ContactSaga from "./sagas/ContactSaga";

function* rootSaga() {
  yield all([
    yield fork(dataSaga),
    yield fork(EmailSaga),
    yield fork(FileSaga),
    yield fork(UserSaga),
    yield fork(FirebaseSaga),
    yield fork(AnalyticsSaga),
    yield fork(RegistrationSaga),
    yield fork(PaymentSaga),
    yield fork(ContactSaga),
    // Add other sagas here if needed
  ]);
}

export default rootSaga;