import React from "react";
import { connect } from "react-redux";
function PrivacyPolicy() {
    return (
        <>
            <div className="max-w-7xl m-auto text-center md:pt-24 text-7xl pb-10">
                Privacy Policy
            </div>
            <div className="max-w-4xl m-auto pb-10">
                Last Updated: 1/29/2024<br/><br/>

Welcome to Respond Pilot, an innovative email application designed to streamline your email responses using AI technology. This Privacy Policy outlines how we collect, use, protect, and disclose information and data when you use our Respond Pilot app and services.<br/><br/>

<br/><br/>
Respond Pilot use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" className="underline text-blue-500">Google API Services User Data Policy</a>, including the Limited Use requirements.
<div>Information Collection and Use</div><br/>
When you use Respond Pilot, we may require access to your Gmail account to create drafts and generate responses. We collect information necessary to provide these services, including:
<br/><br/>
Access to your Gmail account to generate and manage drafts.<br/>
Data from your knowledge base to assist in AI response generation.<br/>
We use this information to:<br/>
<br/>
Create and manage email drafts.<br/>
Generate AI-powered responses.<br/>
Enhance and improve our AI algorithms and services.<br/>
Communicate with you, such as through newsletters and updates, regarding our services.<br/>
Data Protection and Security<br/><br/>
The security of your data is paramount to us. We employ industry-standard practices and technologies to safeguard your information and the privacy of your email content. While we do not store your emails, we do store AI-generated responses to improve our services.
<br/><br/>
AI Privacy Compliance<br/>
How We Use Your Information
<br/>
We use the information collected for the following purposes:
<br/><br/>
Text Processing: We use the OpenAI API to process the text you upload for various purposes, such as generating responses or insights.
<br/>
Improving Our Product: We may use aggregated and anonymized data to analyze usage patterns and improve the functionality and performance of our product.
<br/>
Communication: We may contact you to provide updates about our product, respond to your inquiries, and send important information regarding your use of our product.
<br/><br/>
Use of Data for Communication<br/>
We may use your account information to send you service-related communications, including updates and newsletters. You will have the ability to opt-out of such communications.<br/>
<br/>
Changes to Our Privacy Policy<br/>
Please note that we may update this privacy policy from time to time without prior notice. We encourage you to review this policy periodically for any changes.
<br/><br/>
Contact Information<br/>
If you have any questions or concerns about this privacy policy or our practices, please contact us at [Your Contact Information].
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
