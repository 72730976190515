import React, { useEffect, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { verifyTokenOrInvalidate } from "./../store/reducers/UserSlice";

const AuthGuard = ({
    children,
    client,
    doVerifyTokenOrInvalidate,
}) => {
    const location = useLocation();

    const memoizedVerifyClientOrInvalidate = useCallback(() => {
        doVerifyTokenOrInvalidate();
        console.log(location.pathname);
    }, [doVerifyTokenOrInvalidate, location]);

    useEffect(() => {
        memoizedVerifyClientOrInvalidate();
    }, [memoizedVerifyClientOrInvalidate]);

    if (Object.keys(client).length === 0) {
        return <Navigate to="/" />;
    }

    return <div>{children}</div>;

}

const mapDispatchToProps = (dispatch) => ({
    doVerifyTokenOrInvalidate: payload => dispatch(verifyTokenOrInvalidate()),
});

const mapStateToProps = (state) => ({
    client: state.userSlice.client,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
