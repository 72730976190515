import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import AdminLayout from "../../components/admin/Layout";
import {
    firebaseGetSettings,
    firebaseUpdateSettings,
    firebaseGetAverageTimeUpdate,
} from "../../store/Actions";

const Admin = ({
    doFirebaseGetSettings,
    doFirebaseUpdateSettings,
    doFirebaseGetAverageTimeUpdate,
    openAiTurbo,
    user,
    average,
}) => {
    const memoizedDoFirebaseGetSettings = useCallback(() => {
        doFirebaseGetSettings()
    }, [doFirebaseGetSettings]);

    useEffect(() => {
        memoizedDoFirebaseGetSettings()
    }, [memoizedDoFirebaseGetSettings]);

    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">Home</div>

        </AdminLayout>
    );
}
const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetSettings: () => dispatch(firebaseGetSettings()),
    doFirebaseUpdateSettings: (data) => dispatch(firebaseUpdateSettings(data)),
    doFirebaseGetAverageTimeUpdate: () => dispatch(firebaseGetAverageTimeUpdate()),
});

const mapStateToProps = (state) => ({
    openAiTurbo: state.firebase.settings.openAiTurbo,
    average: state.firebase.settings.average,
    user: state.userSlice,
    token: state.userSlice.token,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);