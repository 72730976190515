import React from "react";
import { connect } from "react-redux";
import {Helmet} from "react-helmet";
import BouncyRedButton from "../components/ui/BouncyRedButton";
import BouncyWhiteButton from "../components/ui/BouncyWhiteButton";

function Home() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Respond Pilot</title>
                <link rel="canonical" href="https://RespondPilot.com" />
            </Helmet>
            <div className="max-w-7xl m-auto grid md:grid-cols-2 px-10 md:pt-32 mb-16">
                <div>
                    <div className="text-sm font-bold leading-6">Respond Pilot</div>
                    <div className="text-7xl font-semibold pb-3">
                        Your email <br/>Wingman,
                    </div>
                    <div className="text-7xl font-semibold text-primary-red pb-5">
                        taking you to<br/> new Heights!
                    </div>
                    <div className="text-3xl font-normal leading-10 pb-6">
                        Respond Pilot crafts professional, personalized AI responses using your company's information - from inventory details, common question, event schedules, and more.
                    </div>
                    <BouncyRedButton to="/login">
                        Get Started
                    </BouncyRedButton>
                    <div className="text-md pt-2">
                        Join today in minutes!
                    </div>
                </div>
                <div className="relative">
                    <img className="absolute -top-7 transform rotate-[20deg] w-32" src="/images/launch-icon.svg" alt="Description"/>
                    <img className="m-auto rounded-lg" src="/images/RespondPilotGif05.gif" alt="Woman raising hand to sky"/>
                </div>
            </div>
            <div className="bg-white pb-10">
                <img src="/images/paper-tear-top.svg" alt="Description"/>
            </div>
            <div className="bg-white pb-20">
                <div className="max-w-7xl m-auto bg-white">
                    <div className="grid md:grid-cols-2 md:pt-24 md:pl-20 md:pr-20">
                        <div className="">
                            <img className="transform -rotate-45" src="/images/hang-loose.svg" alt="Description"/>
                            <div className="text-5xl font-bold leading-tight tracking-tight">
                                Email Whisperer
                            </div>
                            <div className="text-2xl font-normal leading-9 md:pr-20 text-gray-text/70 pt-5">
                                Think of us as your email's new best friend. Respond Pilot reads, understands, and drafts replies to your customer emails, magically transforming your overwhelming influx into a neat stack of ready-to-send answers.
                            </div>
                        </div>
                        <div>
                            <img className="rounded-lg" src="/images/RespondPilotVid01.gif" alt="man sitting at computer smiling"/>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:pt-24 md:pl-20 md:pr-20">
                        <div>
                            <img className="" src="/images/world.svg" alt="Description"/>
                            <div className="text-5xl font-bold leading-tight tracking-tight">
                                Your Business, But Smarter
                            </div>
                            <div className="text-2xl font-normal leading-9 md:pr-20 text-gray-text/70 pt-5">
                                Respond Pilot is the brainy sidekick to your business data. Whether it's the details in your inventory, the fine print in your FAQs, upcoming events, we're on it – crafting responses that are so spot-on, your customers will think it's you.
                            </div>
                        </div>
                        <div>
                            <img className="rounded-lg" src="/images/RespondPilotVid02.gif" alt="man sitting at computer smiling"/>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:pt-24 md:pl-20 md:pr-20">
                        <div className="">
                            <img className="" src="/images/drink.svg" alt="Description"/>
                            <div className="text-5xl font-bold leading-tight tracking-tight">
                                Click, Check, Cheer!
                            </div>
                            <div className="text-2xl font-normal leading-9 md:pr-20 text-gray-text/70 pt-5">
                                Our AI drafts, you dazzle. Just a quick peek and a click, and you're sending responses that make your customers think, "Wow, they really know their stuff!"
                            </div>
                        </div>
                        <div>
                            <img className="rounded-lg" src="/images/image4.png" alt="man sitting at computer smiling"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-2 bg-white">
                <img className="transform rotate-180 m-0" src="/images/paper-tear-green.svg" alt="Description"/>
            </div>
            <div className="bg-green-header">
                <div className="max-w-7xl m-auto text-white pb-20">
                    <div className="grid md:grid-cols-2 gap-10 md:pt-24">
                        <div>
                            <div className="text-5xl font-semibold leading-tight tracking-tight">
                                It's time to embrace efficiency, accuracy, and a touch of AI magic.&nbsp;&nbsp; 
                                <span className="font-semibold text-primary-red pb-5">Start your journey today</span> and watch your emails soar to new heights.
                            </div>
                            <div className="pt-5">
                                <BouncyWhiteButton to="/login">
                                    Get Started
                                </BouncyWhiteButton>
                            </div>
                            <div className="text-sm pt-2">
                                Join over 1 satisfied Respond Pilot Customer.
                            </div>
                        </div>
                        <div>
                            <img className="rounded-lg" src="/images/image5.jpeg" alt="man sitting at computer smiling"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-2 bg-white">
                <img className="m-0" src="/images/paper-tear-green.svg" alt="Description"/>
            </div>
            
            
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
