import { all, fork, takeLeading, call } from "redux-saga/effects";
import { toast } from 'react-toastify';

import {
    collectContact,
} from "../../store/Actions";

import {
    fetchCollectContact,
} from "../../api/Api";

function* handleCollectContact({payload}) {
    try {
        yield call(fetchCollectContact, {
            contact: payload,
        });
        toast.success('Message Sent', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    } catch (error) {
        toast.error(error.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}

function* watchDataSaga() {
    yield takeLeading(collectContact.type, handleCollectContact);
}

export default function* dataSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}
