import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import GoogleLogin from "../components/ui/GoogleLogin";
import {
    setWebpraisalValue,
} from "./../store/Actions";
import { login } from "./../store/reducers/UserSlice";

function Login({
    doLogin,
    doSetWebpraisalValue,
    signInSuccess,
    user,
}) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const code = searchParams.get('code');
    // const scope = searchParams.get('scope');

    const memoizedLogin = useCallback(() => {
        doLogin(code)
    }, [doLogin, code]);

    useEffect(() => {
        if (code !== null) {
            memoizedLogin();
        }
    }, [memoizedLogin, code]);

    useEffect(() => {
        if (signInSuccess) {
            if (user?.status === "new") {
                navigate("/emails");
                // navigate("/registration");
            }
            if (user?.status === "paid" || (user?.status === "cancelled")) {
                navigate("/emails");
            }
            doSetWebpraisalValue({index: "signInSuccess", value: false});
        }
    }, [navigate, signInSuccess, doSetWebpraisalValue, user]);
    return (
        <div className="max-w-7xl m-auto text-center md:pt-12 md:pb-10 h-screen">
            <img className="m-auto w-96" alt="Respond Pilot Pete" src="/images/RespondPilotPete.png"/>
            <div className="text-3xl font-semibold">Sign-in with Gmail.</div>
            <div className="w-fit m-auto">
                <GoogleLogin/>
            </div>
            <div className="flex flex-row w-96 m-auto">
                <div className="h-1 w-52 bg-black mt-2"></div>
                <div className="w-[30rem]">Only compatible with Gmail</div>
                <div className="h-1 w-52 bg-black mt-2"></div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doLogin: payload => dispatch(login(payload)),
    doSetWebpraisalValue: payload => dispatch(setWebpraisalValue(payload)),
});

const mapStateToProps = (state) => ({
    signInSuccess: state.webpraisal.signInSuccess,
    user: state.userSlice.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
