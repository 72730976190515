import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function SchedulerButton({
    children,
    to,
}) {
    const [activeClass, setActiveClass] = useState("");

    const handleMouseEnter = () => {
        setActiveClass("bouncy-button-in");
    };

    const handleMouseLeave = () => {
        setActiveClass("bouncy-button-out");
    };

    const handleClick = () => {
        setActiveClass("bouncy-button-active");
    };

    const handleUnclick = () => {
        setActiveClass("bouncy-button-in");
    }
    return (
        <div className="text-center bouncy-button-container relative m-auto w-fit hover:cursor-pointer z-20 m-auto h-full">
            <Link
                to={to}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handleClick}
                onMouseUp={handleUnclick}
                >
                <div className="absolute w-full bg-gray-600 h-full -z-10 top-0 rounded-lg"></div>
                <button className={`bg-green-button font-medium text-white
                    text-md py-1 px-2 bouncy-button z-10 w-fit rounded-lg ${activeClass} duration-300 flex flex-row`}
                >
                    { children }
                </button>
            </Link>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    isLoading: state.webpraisal.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerButton);
