import axios from 'axios';

// Extract common function to make code more reusable and maintainable.
const fetchNetlifyFunction = (functionName, body = {}) => {
    return axios.post(`/.netlify/functions/${functionName}`, body);
};

export const fetchEmail = (data) => {
    return fetchNetlifyFunction("getEmail", {data});
}

export const fetchEmails = (data) => {
    return fetchNetlifyFunction("getEmails", {data});
}

export const fetchLogin = (data) => {
    return fetchNetlifyFunction("getLogin", {data});
}

export const fetchEmailsRedirectUrl = (data) => {
    return fetchNetlifyFunction("getEmailsRedirectUrl", {data});
}

export const fetchEmailCreateDraft = (data) => {
    return fetchNetlifyFunction("getEmailCreateDraft", {data});
}

export const fetchEmailUpdateStatusToRead = (data) => {
    return fetchNetlifyFunction("getEmailUpdateStatusToRead", {data});
}


export const fetchAiResponseCreate = (data) => {
    return fetchNetlifyFunction("getAiResponseCreate", {data});
}

export const fetchAiResponseThread = (data) => {
    return fetchNetlifyFunction("getAiResponseThread", {data});
}

export const fetchAiListFiles = (data) => {
    return fetchNetlifyFunction("getAiListFiles", {data});
}

export const fetchAiUploadFile = (data) => {
    return fetchNetlifyFunction("getAiUploadFile", data);
}

export const fetchAiRemoveFile = (data) => {
    return fetchNetlifyFunction("getAiRemoveFile", {data});
}

export const fetchStripePaymentIntent = (data) => {
    return fetchNetlifyFunction("getStripePaymentIntent", {data});
}

export const fetchValidateUser = (data) => {
    return fetchNetlifyFunction("getValidateUser", {data});
}

export const fetchCollectContact = (data) => {
    return fetchNetlifyFunction("getCollectContact", {data});
}

export const fetchRegistrationStep1 = (data) => {
    return fetchNetlifyFunction("getRegistrationStep1", {data});
}

export const fetchRegistrationStep2 = (data) => {
    return fetchNetlifyFunction("getRegistrationStep2", {data});
}



export const fetchScraper = (data) => {
    return fetchNetlifyFunction("getScraper", {data});
};

export const fetchScraperThread = (data) => {
    return fetchNetlifyFunction("getScraperRedditThread", {data});
};

export const fetchFirebaseCreatePersonaData = (data) => {
    return fetchNetlifyFunction("getFirebaseCreatePersonaData", {data});
};

export const fetchFirebaseReadPersonaData = (data) => {
    return fetchNetlifyFunction("getFirebaseReadPersonaData", {data});
};

export const fetchFirebaseCreateActivityRecord = (data) => {
    return fetchNetlifyFunction("getFirebaseCreateActivityRecord", {data});
};

export const fetchFirebaseUpdateActivityRecord = (data) => {
    return fetchNetlifyFunction("getFirebaseUpdateActivityRecord", {data});
};

export const fetchFirebaseGetActivityRecords = (data) => {
    return fetchNetlifyFunction("getFirebaseGetActivityRecords", {data});
};

export const fetchFirebaseGetActivityRecord = (data) => {
    return fetchNetlifyFunction("getFirebaseGetActivityRecord", {data});
};

export const fetchFirebaseGetPersonaDatas = (data) => {
    return fetchNetlifyFunction("getFirebaseGetPersonaDatas", {data});
}





export const fetchFirebaseLogin = (user) => {
    return fetchNetlifyFunction("getFirebaseLogin", { user });
};

export const fetchFirebaseLogout = () => {
  return fetchNetlifyFunction("getFirebaseLogout");
};

export const fetchFirebaseGetCustomToken = (data) => {
    return fetchNetlifyFunction("getFirebaseGetCustomToken", { data });
};

export const fetchGetFirebaseSettings = (data) => {
    return fetchNetlifyFunction("getFirebaseSettings");
}

export const fetchFirebaseSetSetting = (data) => {
    return fetchNetlifyFunction("getFirebaseSetSetting", data);
}

export const fetchOpenAiVisionOneIdentify = (data) => {
    return fetchNetlifyFunction("openAiVisionOneIdentify", data);
}

export const fetchOpenAiPriceCheck = (data) => {
    return fetchNetlifyFunction("openAiPriceCheck", data);
}

export const fetchPerplexityPriceCheck = (data) => {
    return fetchNetlifyFunction("perplexityPriceCheck", data);
}

export const fetchAssistantPriceCheckThread = (data) => {
    return fetchNetlifyFunction("openAiAssistantPriceCheckThread", data);
}

export const fetchAssistantPriceCheckQueue = (data) => {
    return fetchNetlifyFunction("openAiAssistantPriceCheckQueue", data);
}

export const fetchSetSurveyResponse = (data) => {
    return fetchNetlifyFunction("setSurveyResponse", data);
}

export const fetchFirebaseCollectEmail = (data) => {
    return fetchNetlifyFunction("getFirebaseCollectEmail", data);
}

export const fetchGetFirebaseAvaregeTimeUpdate = (data) => {
    return fetchNetlifyFunction("getFirebaseAvaregeTimeUpdate", data);
}

export const fetchGetFirebaseAnalytics = (data) => {
    return fetchNetlifyFunction("getFirebaseAnalytics", data);
}

export const fetchGetFirebaseGetRequests = (data) => {
    return fetchNetlifyFunction("getFirebaseGetRequests", data);
}

export const fetchGetFirebaseGetRequest = (data) => {
    return fetchNetlifyFunction("getFirebaseGetRequest", data);
}

export const fetchGetEbayPriceCheck = (data) => {
    return fetchNetlifyFunction("getEbayPriceCheck", data);
}

export const fetchGetFirebaseGetDocuments = (data) => {
    return fetchNetlifyFunction("getFirebaseDocuments", data);
}