import React, { useState } from "react";
import { connect } from "react-redux";

import SchedulerButton from "../scheduler/SchedulerButton";
import TableRow from "./TableRow";
import ContentBox from "../ui/ContentBox";
import BouncyPaginationButton from "../ui/BouncyPaginationButton";

import {
    getRefresh
} from "../../store/Actions";

function Scheduler({
    emails,
    doGetRefresh,
}) {
    const [ page, setPage ] = useState(1);
    const pageSize = 10;
    const pages = new Array(Math.ceil(emails.length / pageSize)).fill(0);

    function paginate(array, pageNumber, pageSize) {
        // Calculate starting index of the items for the current page
        const startIndex = (pageNumber - 1) * pageSize;
        // Use filter to get items for the current page
        return array.filter((_, index) => index >= startIndex && index < startIndex + pageSize);
    }

    const handleDoGetRefresh = () => doGetRefresh();
    
    return (
        <div className="flex flex-col mr-4 overflow-visible">
            <div className="flex flex-row justify-end w-full pb-2">
                <div onClick={handleDoGetRefresh} className="w-fit" key={2}>
                    <SchedulerButton to="">
                        <img className="h-5 pr-1 m-auto" src="/images/refresh.png" alt="refresh"/>Refresh
                    </SchedulerButton>
                </div>
            </div>
            <ContentBox className="justify-between flex flex-col w-full overflow-visible bg-white">
                <table className="table-auto col-span-2 md:w-[830px] md:h-[520px]">
                    <tbody className="text-black-text text-center">
                        { paginate(emails, page, pageSize).map((item, index) => (
                            <TableRow key={index} index={index} item={item} indexStart={((page - 1) * pageSize) + index}/>
                        ))}
                    </tbody>
                </table>
            </ContentBox>
            <div className="grid grid-cols-10 text-center text-2xl min-w-fit my-5 m-auto gap-2">
                { pages.map((pageNumber, index) => {
                    const displayNumber = index + 1;
                    return (
                        <BouncyPaginationButton key={index} isCurrent={page === displayNumber} handleOnClick={() => setPage(displayNumber)}>
                            { displayNumber }
                        </BouncyPaginationButton>
                    )
                })}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doGetRefresh: payload => dispatch(getRefresh()),
});

const mapStateToProps = (state) => ({
    emails: state.emailSlice.emails,
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
